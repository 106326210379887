.responsive-paddings {
    padding: 20px;
  
    .screen-large & {
      padding: 40px;
    }
}

.app .content-block {
margin-left: 40px;
margin-right: 40px;
margin-top: 20px;
}

.screen-x-small .content-block {
margin-left: 20px;
margin-right: 20px;
}