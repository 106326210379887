.dx-toolbar {
  background-color: rgba(191, 191, 191, .15);
  padding: 5px 10px;
}

.dx-treeview-item-icon-container .dx-toolbar-before {
  width: 36px;
  padding-right: 0px !important;
  text-align: center;
}

.dx-treeview-item-content {
  padding-left: 10px !important;
}

.dx-button {
  background-color: rgba(191, 191, 191, -0.15);
  border: none;
}

// .panel-list {
//   height: 100%;
// }

.dx-drawer-expand.dx-drawer-right .panel-list {
  float: right;
}

.panel-list .dx-treeview-item {
  color: #fff;
  border-top: 1px solid rgba(221, 221, 221, .2);
}

.panel-list .dx-treeview-item .dx-icon {
  color: #fff !important;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, .15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  display: inline-block;
  margin-right: 50px;
}

label {
  font-weight: bold;
}

#content {
  height: 100%;
  padding: 10px 20px;
}

#content h2 {
  font-size: 26px;
}

.content {
  flex-grow: 1;
}

.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.menu-container {
  min-height: 100%;
  display: flex;
  flex: 1;
}

.dx-treeview {
  height: unset;
}

.content, .content-block {
  margin-left: 20px;
  margin-right: 20px;
}