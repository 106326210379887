.dx-filemanager
  .dx-filemanager-thumbnails
  .dx-filemanager-thumbnails-view-port
  .dx-filemanager-thumbnails-item
  .dx-filemanager-thumbnails-item-content
  .dx-filemanager-thumbnails-item-thumbnail {
  border: 0;
  height: 100px;
  width: 100px;
  font-size: 90px;
}
.dx-filemanager
  .dx-filemanager-thumbnails
  .dx-filemanager-thumbnails-view-port
  .dx-filemanager-thumbnails-item {
  width: 100px;
  height: unset;
}